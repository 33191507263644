/**
 * Fonts, base styles and layout stuff
 */

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(../fonts/Inter-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(../fonts/Inter-Medium.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(../fonts/Inter-SemiBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(../fonts/Inter-Bold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Archivo';
  font-weight: 600;
  src: url(../fonts/Archivo-SemiBold.woff2);
  font-display: swap;
}

html {
  background-color: #f7f8f9;
}

.hidden-input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
